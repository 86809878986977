<template>
	<prac-test :test=test>
	</prac-test>
</template>
<script>
import { PracTest } from "@/components";

export default {
  name: "data-link-layer",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "January 2019",
        title: "Data Link Layer",
        subtitle: "Everything related to layer 2",
        description:
          "Online flashcards on the data link layer to understand networks.",
        bg: "/img/bg19.jpg",
        author: "Networks",
        references: "",
        items: [
          {
            front:
              "<h4>What are the 3 main functions of the Data Link Layer?</h4>",
            back:
              "<ul><li>Allows the higher layer to access the phsyical media. Data link layer is responsible for the actual transmission of the packet between nodes in LANs or WANs.</li><li>Handles transmission errors (for e.g. collisions);</li><li>Ensures that the right host (with the MAC address) receives the packet.</li>"
          },
          {
            front: "What are some protocols used on the Data Link Layer?",
            back: "Ethernet, ARP (Address Resolution Protocol)"
          },
          {
            front: "What is the purpose of medium access control?",
            back: "To detect and minimize errors/collisions."
          },
          {
            front: "What are the two types of medium access controls?",
            back:
              "Fixed (a node is given a fixed time slot to send) or Variable (can be central or decentralized)"
          },
          {
            front:
              "What is the difference between centralized and decentralized (distributed) medium access controls?",
            back:
              "Central (for e.g. a controllers polls each station in a pre-specified order to provide access to the channel). In distributed schemes there is no master."
          },
          {
            front:
              "Do normally central or distributed schemes provide a lower access delay?",
            back: "Distributed schemes have usually less overhead."
          },
          {
            front: "<h4>What is the <b>Carrier Sense</b> protocol?</h4>h4>",
            back:
              "A networks protocol that listens before transmitting any data."
          },
          {
            front: "What is meant by No Carrier Sense?",
            back:
              "A station only knows whetehr a transmission has been successful or not (sends without listening first)."
          },
          {
            front: "What is CSMA?",
            back:
              "Carrier Sense Multiple Access. Listens on the medium/carrier before transmitting and only sends when no other station is sending. CMS is part of Media Access Control and implemented by Ethernet."
          },
          {
            front: "What does CSDMA/CD stand for?",
            back: "CSMA with Collision Detection"
          },
          {
            front: "Why is CSMA not always reliable?",
            back: "Because of a propagation speed. => CSDMA/CD needed"
          },
          {
            front:
              "In CSMA. What does a station do if it detects that another node is sending?",
            back:
              "It waits till the other station has finished its transmission."
          },
          {
            front: "What are the two sublayers of the Data Link layer?",
            back: "LLC (Logical Link Control), MAC (Medium Access Control)"
          },
          {
            front: "What is framing?",
            back:
              "The process where the data link layer breaks down the datagrams from the higher layers and converts them into frames that are ready to being transmissioned."
          },
          {
            front: "In CSMA/CD, what is the back-off algorithm?",
            back:
              "The back-off algorithm manages the waiting time after a detected collision. In for e.g. Binary exponential back-off, randomly a waiting time between 0 and 2^n is chosen which is with a high probability different from the other station."
          },
          {
            front:
              "CSMA/CD: Why does the time slot last exactly 51 micro seconds (64 Byte at 10 MBit/s)?",
            back:
              "To guarantee the detection of collision. This is exactly the signal propagation time of an ethernet segment."
          },
          {
            front: "What are the different variations of CSMA?",
            back: "1-persistent, non-persistent, and p-persistent CSMA"
          },
          {
            front:
              "What do the different variations (1-persistent, non-persistent and p-persistent) CSMA protocols have in common?",
            back:
              "In each of these protocols, a station only transmit if it detects that the channel is idle."
          },
          {
            front:
              "Persistence: When do the different variations of CSMA differ in their behavior?",
            back: "When the channel is detected to be busy."
          },
          {
            front: "Describe 1-persistent CSMA.",
            back:
              "Waits until the medium is available and then transmits. If a collision occurs, wait a random amount of time."
          },
          {
            front: "Describe non-persistent CSMA.",
            back:
              "If channel is idle, transmit. If channel is busy, wait a random amount of time."
          },
          {
            front: "Describe p-persistent CSMA.",
            back:
              "1) Listen with probability p until medium is free, or restart the backoff algorithm with probability 1-p. 2) If channel is busy wait till idle and then repeat 1)."
          },
          {
            front:
              "What is an advantage and a disadvantage of 1-persistent CSMA?",
            back:
              "A short waiting time, but there is the possibility of a second collision."
          },
          {
            front:
              "What is an advantage and a disadvantage of non-persistent CSMA?",
            back: "less collision but longer waiting times"
          },
          {
            front: "CSMA What back-off algorithm does Ethernet use?",
            back: "1-persistent CSMA"
          },
          {
            front: "Where is CSMA/CD and where is CSMA/CA used?",
            back:
              "CSMA/CD in Ethernet and CSMA with Collision Avoidance in WLANs"
          },
          {
            front: "Unlike TCP what does UDP not have to send for each packet?",
            back:
              "UDP doesn't need to send an acknowledgement to the transmitted side."
          },
          {
            front: "<h4>What is a frame (Ethernet)?</h4>",
            back:
              "In Ethernet, the sent data units are called frames. One frame is 64 bytes."
          },
          {
            front: "What is the structure of an Ethernet frame (64 bytes)?",
            back:
              "preamble (8), destination address (6), source address (6), length (2), payload (0-1454), pad (0-46), CRC (4)"
          },
          {
            front: "Frame: What is the preamble?",
            back:
              'The preamble is an oscillation used to synchronize the transmitter with the receive so that the receiver can "hear" the frequency.'
          },
          {
            front: "Frame: Does the length field include the pad?",
            back: "Yes."
          },
          {
            front: "What is the pad field in an Ethernet frame?",
            back:
              "If the size of a frame is less than 64 bytes it is invalid. Therefore, a padding is added to smaller frames so that the full size of the frame becomes 64 bytes."
          },
          {
            front: "Frame: What is the CRC field?",
            back:
              "The CRC (Cyclic Redundancy Check) is a FCS (frame check sequence) algorithm. This is a control sequence that allows detection of corrupted data."
          },
          {
            front: "<h4>What are the three wireless modes (IEEE 802.11)?</h4>",
            back: "Ad Hoc (mesh), Infrastructure and station mode."
          },
          {
            front:
              "What is the difference between WLAN Infrastructure mode vs Adhoc (Mesh) mode?",
            back:
              "<ul><li>An <b>adhoc mode</b> wireless network consists of stations that directly communicate with each other without the interaction of an access point (AP).</li><li>In <b>infrastructure mode</b> the stations communicate with the other stations always through an AP. The AP bridges (converts) wireless (802.11) packets to ethernet (802.3) packets. In home networks the router is often setup as an AP.</li></ul>"
          },
          {
            front:
              "Describe the Hidden Node Problem (hidden terminal problem).",
            back:
              "The hidden node problem occurs when a station is visible to a wireless access point, but hidden from other nodes communicating with the same access point. Given three stations (wireless): A - B - C. Since station A and C are out of range of each other, they cannot communicate with each other."
          },
          {
            front: "<h4>What is the Exposed Node Problem?</h4>",
            back:
              "Given four nodes: B1 - A1 - B2 - A2. The exposed node problem occurs when A1 is sending to B1 and B2 wants to send to A2. B2 thinks according to carrier sense that it cannot sense as its neighbor A2 is sending even though it couldn't without a problem."
          }
        ]
      }
    };
  }
};
</script>
